<app-topnav></app-topnav>
<app-sidenavbar></app-sidenavbar>
<div class="main-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 title-section">
        <h3>Add Violation</h3>
      </div>
    </div>
    <div class="row mx-5 my-2">
      <ngb-alert #selfClosingAlert [type]="alerttype" *ngIf="isalert">{{
        message
      }}</ngb-alert>
    </div>
    <div class="row m-5">
      <form (ngSubmit)="submitted(form.value)" #form="ngForm">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Select Employee:</label
          >
          <ng-multiselect-dropdown
          name="empnames"
          [settings]="dropdownSettings"
          [(ngModel)]="selectedemp"
          [data]="empnames"
          (onSelect)="onItemSelect($event)"
            required
          ></ng-multiselect-dropdown>
        </div>
        <div class="mb-3">
          <label for="violationweb" class="form-label">violation website</label>
          <ng-select
            [items]="webNames"
            [addTag]="addTagFnW"
            [hideSelected]="true"
            [multiple]="true"
            bindLabel="viloation_name"
            name="webname"
            [(ngModel)]="selectedWebsite"
            
          >
          </ng-select>
        </div>
        <div class="mb-3">
          <label for="violationapp" class="form-label">violation app</label>
          <ng-select
            [items]="appNames"
            [addTag]="addTagFnA"
            [hideSelected]="true"
            [multiple]="true"
            bindLabel="viloation_name"
            name="appname"
            [(ngModel)]="selectedApp"
          >
          </ng-select>
        </div>
        <button class="btn add-btn" [disabled]="!form.valid">Submit</button>
      </form>
    </div>
    <div class="row">
      
      <div class="departmentauto">
        <table id="customers" class="styled-table">
          <thead>
            <tr>
              <th>Violation Website & apps</th>
              <th>Active Status</th>
              <th>Update Active Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of violationsites">
              <td>{{ "www." + item["viloation_name"] + ".com" }}</td>
              <td
                [ngStyle]="{
                  color: item['is_viloation_allowed'] ? 'green' : 'red'
                }"
              >
                {{ item["is_viloation_allowed"] ? "productive" : "unproductive" }}
              </td>
              <td>
                <button
                  class="btn btn-primary btn-productive"
                  (click)="updateapp(item['_id'], !item['is_viloation_allowed'])"
                >
                  {{ item["is_viloation_allowed"] ? "unproductive" : "productive" }}
                </button>
              </td>
            </tr>
            <tr *ngFor="let item of violationapps">
              <td>{{ item["viloation_name"] + ".exe" }}</td>
              <td
                [ngStyle]="{
                  color: item['is_viloation_allowed'] ? 'green' : 'red'
                }"
              >
                {{ item["is_viloation_allowed"] ? "productive" : "unproductive" }}
              </td>
              <td>
                <button
                  class="btn btn-primary btn-productive"
                  (click)="updateapp(item['_id'], !item['is_viloation_allowed'])"
                >
                  {{ item["is_viloation_allowed"] ? "unproductive" : "productive" }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
