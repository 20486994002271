import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable(
    {
        providedIn: 'root'
    }
)
export class appservice {
    // url = 'http://localhost:8080'
    url = 'https://app.pulseye.in';

    submitted = new Subject<String>();
    offlinenumber = new Subject<number>();
    onlinenumber = new Subject<number>();
    daterange = new Subject<object>();
}