import { DesktopApp } from './models/desktop.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { employeemodel } from 'src/app/models/employee.model';
import { appservice } from '../app.service';
import { Website } from './models/website.model';
import { ManagerService } from './../services/manager.service';

@Component({
  selector: 'app-addviolation',
  templateUrl: './addteamleads.component.html',
  styleUrls: ['./addteamleads.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddteamleadsComponent implements OnInit {
  [x: string]: any;

  empnames: Array<string> = [];
  departments: any = [];
  selectedDepartment: any = [];
  totallicenses: any[] = [];
  totalDepartments: any[] = [];
  selectedemp: any;
  dropdownSettings: any;
  selectedWebsite: any;
  selectedApp: any;
  Webname: any[] = [];
  webNames: any[] = [];
  appNames: any[] = [];
  appName: any[] = [];
  violationsites: Website[] = [];
  violationapps: DesktopApp[] = [];
  message: string = '';
  isalert: boolean = false;
  alerttype: string = '';
  error: string = '';
  teaLic?: Boolean;
  constructor(private http: HttpClient, private service: appservice, private managerService: ManagerService, private modalService: NgbModal, ) { }

  ngOnInit(): void {
    this.teaLic = false
    this.OrgTotalLicenses();
    this.getlicenses();
    this.getDepartements();
    
    
    /* Srini */
    console.log("ngOnInit");
    
    /* Desktop Apps & Desktop Sites Fetching */
    this.getviolationwebsites('');
    this.getviolationdesktopapp('');
    this.dropdownSettingsD = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "team_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
      this.managerService.getEmployees(0).pipe(map((res: any) => {
        let emp2: employeemodel[] = []
      let getResp = res['listOfEmployess'];
      for (let employee of getResp) {
        let _id = employee['_id'];
        let emp_name = employee['emp_name'];
        let emp_email = employee['emp_email'];
        let emp_last_login = employee['emp_last_login'];
        let emp_departement = employee['emp_departement'];
        let emp_lic_assigned_from = employee['emp_lic_assigned_from'];
        let emp_lic_assigned_till = employee['emp_lic_assigned_till'];
        let emp_lic_id = employee['emp_lic_id'];
        let emp_login_status = employee['emp_login_status'];
        let emp = new employeemodel(_id, emp_name, emp_email, emp_last_login, emp_departement, emp_lic_assigned_from, emp_lic_assigned_till, emp_lic_id, emp_login_status)
        emp2.push(emp)
        
      }
      return emp2
      })).subscribe((res: any) => {
         console.log("managerService res");
         console.log(res);
        this.empnames = res.map((emp:any) => {
          return {
            _id:emp['_id'],
            emp_name:emp['emp_name']
          }
        })
        console.log("empnames res");
         console.log(this.empnames);
      })
    /* Srini */
    this.form.get("empDepartment").valueChanges
    .subscribe((f:any)=> {
      this.onDepartmentChanged(f);
  })
  }
  OrgTotalLicenses(){
    this.http.get(`${this.baseUrl}availabilityoranizationlicenses?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe((data: any) => {
      console.log("OrgTotalLicenses");
      console.log(data);
      if (data['status']) {
        this.OrgTotalLicense = data.count;
        //this.totallicenses = data['total_licenses'];
      }
    })
  }
  getlicenses() {
    this.managerService.getLicenses().subscribe((data: any) => {
      if (data['status']) {
        this.totallicenses = data['total_licenses'];
      }
    })
  }
  getDepartements(){
    this.managerService.getDepartements().subscribe((data: any) => {
      if (data['status']) {
        this.totalDepartments = data['departments'];
      }
    })
    

  }
  submitted(value: any) {
    //value.emplicense._id, value.emplicense.license_start, value.emplicense.license_end,
    let itemD = this.selectedDepartment.map((data: any) => data['_id']);
    console.log(itemD);
    console.log(value);
    console.log("addTeamlead");
    let license_count = value.license_count;
    let license_start = value.emplicense.license_start;
    let license_end = value.emplicense.license_end;
    let licenseKey = value.emplicense._id;
    let email = value.email;
    let name = value.empname;
    let dept_id = value.empDepartment._id;
    let Is_emp_monitored = value.Is_emp_monitored;
    let team_id = value.teamname[0]._id;
    let body = {
        Is_emp_monitored:Is_emp_monitored,
        license_count:license_count,
        license_start:license_start,
        license_end:license_end,
        licenseKey:licenseKey,
        email:email,
        name:name,
        dept_id:dept_id,
        team_id:team_id
    }
    console.log(body)
    this.managerService.assignTeamLead(body).subscribe((data: any) => {
      console.log(data)
      if (data['status']) {
                  this.isalert = true;
                  this.alerttype = "success"
                  this.message = "Successfully created admin !!"
              setTimeout(() => {
                this.isalert = false;
                this.alerttype = '';
                this.message = ''
              }, 3000);
      } else {
        this.isalert = true;
                  this.alerttype = "failed"
                  this.message = "Something went wrong!!"
              setTimeout(() => {
                this.isalert = false;
                this.alerttype = '';
                this.message = ''
              }, 1000);
              value.reset();
      }
      
    })
    

  }

  createViolationSites = (emp_id: any[] = [], violatiom_web_id: any[] = [], violatiom_app_id: any[] = []) => {
    this.managerService.createViolationSites({ emp_id, violatiom_web_id, violatiom_app_id }).subscribe((data: any) => {
      
      if (data['status']) {
        console.log("errorOne");
                  this.isalert = true;
                  this.alerttype = "success"
                  this.message = "successfully created !!"
              setTimeout(() => {
                this.isalert = false;
                this.alerttype = '';
                this.message = ''
                this.getviolationdesktopapp('');
                 this.getviolationwebsites('');
              }, 5000);
              //this.updateDataAgent();
        
      } else {
        console.log("error");
        this.error = data['message'];
        setTimeout(() => {
          this.error = ''
        }, 1000)
      }
    })
  }
  addTagFnW(name: any) {
    console.log('addTagFn');
    console.log(name);
    return { viloation_name: name, tag:'web' };
  }
  addTagFnA(name: any) {
    console.log('addTagFn');
    console.log(name);
    return { viloation_name: name, tag:'app' };
  }
  updateDataAgent() {
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    this.http.post(`${url}/api/agent/violations`, { name: this.selectedemp[0]._id }, {
      headers: new HttpHeaders({
        'x-auth-token': t
      })
    }).subscribe(res => { })
  }

  /* Srini */
  getviolationwebsites(name: any) {
    this.managerService.getViolationWebSites(name).subscribe((reswebb: any) => {
      if(name != '')
      {
        this.violationsites = reswebb['listOfAsgnViolSites'];
        this.webNames = reswebb['listOfViolationSites'];
        this.webNames = this.webNames.filter(val => !reswebb['listOfAsgnViolSites'].find((v:any) => v['viloation_name'].includes(val.viloation_name)));
        console.log("this_violationsites");
        console.log(this.violationsites);
      }
      else
      {
        this.webNames = reswebb['listOfViolationSites'];
      }
      
    })
  }
   /* Srini */
   onItemSelect(name: any) {
    console.log("onItemSelect");
     console.log(name);
  }
  
  onDepartmentChanged(value: any) {
    console.log('onCountryChanged')
    console.log(value)
    this.deptLic = value.available_license_count;
    console.log(this.deptLic);
    this.fetch_deptid = value._id
    let body = {dept_id:value._id}
        this.teaLic = true;
        this.managerService.fetchTeams(body).subscribe((depart_data: any) => {
          console.log(depart_data);
          this.teams = depart_data['teams'];
          console.log("teams");
          console.log(this.teams);
        })
  }
  getviolationdesktopapp(name: any) {
    this.managerService.getViolationDesktopApp(name).subscribe((resapp: any) => {
      if(name != '')
      {
        this.violationapps = resapp['listOfAsgnViolApps'];
        this.appNames = resapp['listOfViolationApps'];
        this.appNames = this.appNames.filter(val => !resapp['listOfAsgnViolApps'].find((v:any) => v['viloation_name'].includes(val.viloation_name)));
        console.log("this_violationapps");
        console.log(this.violationapps);
      }
      else
      {
        this.appNames = resapp['listOfViolationApps'];
      }

    })

  }
  updateapp(id: string, status: boolean) {
    console.log({id: id, active: status});
    console.log(this.selectedemp);
    let emp_id = this.selectedemp._id;
    this.managerService.updateviolation({id: id, active: status}).subscribe((res:any) => {
      console.log(res);
      this.getviolationdesktopapp(this.selectedemp[0]._id);
      this.getviolationwebsites(this.selectedemp[0]._id);
      //this.updateDataAgent();
    })
  }

}
