import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { employeemodel } from 'src/app/models/employee.model';
import { appservice } from '../app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, OnDestroy {
  baseUrl = environment.baseUrl;
  [x: string]: any;
  online: number = 0;
  offline: number = 0;
  response: employeemodel[] = [];
  // token:string |null=''
  constructor(private service: appservice, private http: HttpClient) { }

  ngOnInit(): void {
    this.udata = JSON.parse(`${localStorage.getItem('userData')}`);
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    this.userType = this.udata.role;
    this.userName = this.udata.emp_name;
    console.log(this.userName);
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    this.fetchEmployees(1);
    this.service.submitted.subscribe(val => {
      this.fetchEmployees(1);
    })
    

    // this.service.onlinenumber.subscribe((res) => {
    //   this.online = res
    // })
    // this.service.offlinenumber.subscribe((res) => {
    //   this.offline = res;
    // })
  }
  fetchEmployees(pageno: number) {
    this.http.get<employeemodel[]>(`${this.baseUrl}workers?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&page_no=1&clientId=${this.loginData.clientId}`).pipe(map((res: any) => {
      console.log("res");
      console.log(res);
      let emp2: employeemodel[] = []
      let getResp = res['listOfEmployess'];
      //localStorage.setItem('emp_total', res.total_pages)
      for (let employee of getResp) {
        let _id = employee['_id'];
        let emp_name = employee['emp_name'];
        let emp_email = employee['emp_email'];
        let emp_last_login = employee['emp_last_login'];
        let emp_departement = employee['emp_departement'];
        let emp_lic_assigned_from = employee['emp_lic_assigned_from'];
        let emp_lic_assigned_till = employee['emp_lic_assigned_till'];
        let emp_lic_id = employee['emp_lic_id'];
        let emp_login_status = employee['emp_login_status'];
        let emp = new employeemodel(_id, emp_name, emp_email, emp_last_login, emp_departement, emp_lic_assigned_from, emp_lic_assigned_till, emp_lic_id, emp_login_status)
        emp2.push(emp)
        
      }
      return emp2
    })).subscribe((response) => {
      let offline = response.filter(val => val['emp_login_status'] === false);
      let online = response.filter(val => val['emp_login_status'] === true);
      this.online = online.length;
      this.offline = offline.length;


    })
  }
  scroll() {
    
    window.scrollTo(0, document.body.scrollHeight)
  }
  ngOnDestroy() {
    let url = this.service.url;
    this.http.post(`${url}/api/agent/removelisteners`, {}).subscribe(() => {
      console.log('component removed')
    })
  }
  onPageChange(pageno: number) {
    this.fetchEmployees(pageno);
  }

}
