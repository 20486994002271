import { DesktopApp } from './models/desktop.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { employeemodel } from 'src/app/models/employee.model';
import { appservice } from '../app.service';
import { Website } from './models/website.model';
import { ManagerService } from './../services/manager.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-addviolation',
  templateUrl: './addviolation.component.html',
  styleUrls: ['./addviolation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddviolationComponent implements OnInit {
  [x: string]: any;
  baseUrl = environment.baseUrl;
  empnames: Array<string> = [];
  selectedemp: any;
  dropdownSettings: any;
  selectedWebsite: any;
  selectedApp: any;
  Webname: any[] = [];
  webNames: any[] = [];
  appNames: any[] = [];
  appName: any[] = [];
  violationsites: Website[] = [];
  violationapps: DesktopApp[] = [];
  message: string = '';
  isalert: boolean = false;
  alerttype: string = '';
  error: string = '';
  constructor(private http: HttpClient, private service: appservice, private managerService: ManagerService, private modalService: NgbModal, ) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    /* Srini */
    /* Desktop Apps & Desktop Sites Fetching */
    this.getviolationwebsites('');
    this.getviolationdesktopapp('');
    this.http.get(`${this.baseUrl}employees?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).pipe(map((res: any) => {
        let emp2: employeemodel[] = [];
      let getResp = res['listOfEmployess'];
      for (let employee of getResp) {
        let _id = employee['_id'];
        let emp_name = employee['emp_name'];
        let emp_email = employee['emp_email'];
        let emp_last_login = employee['emp_last_login'];
        let emp_departement = employee['emp_departement'];
        let emp_lic_assigned_from = employee['emp_lic_assigned_from'];
        let emp_lic_assigned_till = employee['emp_lic_assigned_till'];
        let emp_lic_id = employee['emp_lic_id'];
        let emp_login_status = employee['emp_login_status'];
        let emp = new employeemodel(_id, emp_name, emp_email, emp_last_login, emp_departement, emp_lic_assigned_from, emp_lic_assigned_till, emp_lic_id, emp_login_status)
        emp2.push(emp)
        
      }
      return emp2
      })).subscribe((res: any) => {
         console.log("managerService res");
         console.log(res);
        this.empnames = res.map((emp:any) => {
          return {
            _id:emp['_id'],
            emp_name:emp['emp_name']
          }
        })
        console.log("empnames res");
         console.log(this.empnames);
      })
      this.dropdownSettings = {
        singleSelection: true,
        defaultOpen: false,
        idField: "_id",
        textField: "emp_name",
        enableCheckAll: false,
        allowSearchFilter: true
      }
    /* Srini */
  }

  submitted(value: any) {
    let empnames;
    let webname; 
    let appname;
    empnames = value.empnames;
    webname = value.webname;
    appname = value.appname;
    let emp_id = empnames.map(function(a: any){return a._id});
    let violatiom_web_id = webname.map(function(b: any){return{id:b._id, viloation_name:b.viloation_name,violation_type:'web'}});
    let violatiom_app_id = appname.map(function(c: any){return {id:c._id, viloation_name:c.viloation_name,violation_type:'app'}});
    
    this.createViolationSites(emp_id, violatiom_web_id, violatiom_app_id);
    setTimeout(() => {
      this.isalert = false;
      this.alerttype = '';
      this.message = ''
      this.getviolationdesktopapp(this.selectedemp[0]._id);
      this.getviolationwebsites(this.selectedemp[0]._id);
    }, 5000);

    this.updateDataAgent();

  }

  createViolationSites = (emp_id: any[] = [], violatiom_web_id: any[] = [], violatiom_app_id: any[] = []) => {
    console.log("createViolationSites");
   //console.log( emp_id,violatiom_web_id,violatiom_app_id)
    let body = {
      primary_mgr_id:this.loginData.primary_mgr,
      mgr_id:this.loginData._id,
      org_id:this.loginData.org_id,
      emp_token:this.loginData.token,
      clientId:this.loginData.clientId,
      emp_id:emp_id,
      violatiom_web_id:violatiom_web_id,
      violatiom_app_id:violatiom_app_id
    };
    console.log(body);
    this.http.post(`${this.baseUrl}createviolationsites`, body).subscribe((data: any) => {
      console.log(data);
      if (data.status) {
        console.log("errorOne");
                  this.isalert = true;
                  this.alerttype = "success"
                  this.message = "successfully created !!"
              setTimeout(() => {
                this.isalert = false;
                this.alerttype = '';
                this.message = ''
                this.getviolationdesktopapp('');
                 this.getviolationwebsites('');
              }, 5000);
              //this.updateDataAgent();
        
      } else {
        console.log("error");
        this.error = data.message;
        setTimeout(() => {
          this.error = ''
        }, 1000)
      }
    })
  }
  addTagFnW(name: any) {
    console.log('addTagFn');
    console.log(name);
    return { viloation_name: name, tag:'web' };
  }
  addTagFnA(name: any) {
    console.log('addTagFn');
    console.log(name);
    return { viloation_name: name, tag:'app' };
  }
  updateDataAgent() {
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    this.http.post(`${url}/api/agent/violations`, { name: this.selectedemp[0]._id }, {
      headers: new HttpHeaders({
        'x-auth-token': t
      })
    }).subscribe(res => { })
  }

  /* Srini */
  getviolationwebsites(name: any) {
    console.log("getviolationwebsites");
    console.log(name);
    this.http.get(`${this.baseUrl}violationsites?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&name=${name}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe((reswebb: any) => {
      if(name != '')
      {
        this.violationsites = reswebb['listOfAsgnViolSites'];
        this.webNames = reswebb['listOfViolationSites'];
        if(reswebb['listOfAsgnViolSites'].length != 0)
        {
        this.webNames = this.webNames.filter(val => !reswebb['listOfAsgnViolSites'].find((v:any) => v['viloation_name'].includes(val.viloation_name)));
        }
        console.log("this_violationsites");
        console.log(this.violationsites);
        console.log("this.webNames");
        console.log(this.webNames);
      }
      else
      {
        this.webNames = reswebb['listOfViolationSites'];
        console.log("this_violationsites");
        console.log(this.webNames);
      }
      
    })
  }
   /* Srini */
   onItemSelect(name: any) {
    console.log("onItemSelect");
     console.log(name);
    this.getviolationdesktopapp(name._id);
    this.getviolationwebsites(name._id);
  }

  getviolationdesktopapp(name: any) {
    this.http.get(`${this.baseUrl}violationapps?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&name=${name}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe((resapp: any) => {
      if(name != '')
      {
        this.violationapps = resapp['listOfAsgnViolApps'];
        this.appNames = resapp['listOfViolationApps'];
        if(resapp['listOfAsgnViolApps'].length!=0) {
          this.appNames = this.appNames.filter(val => !resapp['listOfAsgnViolApps'].find((v:any) => v['viloation_name'].includes(val.viloation_name)));
        }
        console.log("this_violationapps");
        console.log(this.violationapps);
        console.log("this.appNames");
        console.log(this.appNames);
      }
      else
      {
        this.appNames = resapp['listOfViolationApps'];
        console.log("this_violationapps");
        console.log(this.appNames);
      }

    })

  }
  updateapp(id: string, status: boolean) {
    console.log({id: id, active: status});
    console.log(this.selectedemp);
    let emp_id = this.selectedemp._id;
    this.managerService.updateviolation({id: id, active: status}).subscribe((res:any) => {
      console.log(res);
      this.getviolationdesktopapp(this.selectedemp[0]._id);
      this.getviolationwebsites(this.selectedemp[0]._id);
      //this.updateDataAgent();
    })
  }

}
