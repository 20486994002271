<div class="datepickcontainer">
  <div class="form-group">
    <div
      class="input-group"
      name="daterangeInput"
      daterangepicker
      [options]="options"
      (selected)="selectedDate($event)"
      (applyDaterangepicker)="applyDate($event)"
    >
      <span class="form-control uneditable-input" class="left-datepick">
        {{ daterange.start | date: "MMMM d, y" }} -
        {{ daterange.end | date: "MMMM d, y" }}
      </span>
      <div class="my-2">
        <i
          class="glyphicon glyphicon-calendar fa fa-calendar"
          style="font-size: large"
        ></i>
      </div>
    </div>
  </div>
</div>
