import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public clientId: string = "e688f3eb8c985643081df0c74e45c3dc1";
  public localData = localStorage.getItem('userData') ? localStorage.getItem('userData') : '';
  public userData = this.localData ? JSON.parse(`${this.localData}`) : '';
   
  constructor() { }
}
