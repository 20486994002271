<app-topnav></app-topnav>
<app-sidenavbar></app-sidenavbar>
<div class="main-container">
  <div class="container-fluid">
    <div class="row" style="margin: 0">
      <div class="col-12 title-section">
        <h3>Teams</h3>
      </div>
    </div>
    <div class="row department-btn">
      <div class="col-md-3 col-sm-12 ptdep">
        <!-- <h1>Angular 8 Bootstrap Modal Popup Example</h1> -->
        <button *ngIf="this.loginData.role === 'admin'" class="btn add-btn" (click)="open(mymodall)">
          <i class="fa fa-plus-circle" aria-hidden="true"></i> ADD TEAMT
        </button>
        <button *ngIf="this.loginData.role === 'mgr'" class="btn add-btn" (click)="open(mymodall)">
          <i class="fa fa-plus-circle" aria-hidden="true"></i> ADD TEAMT
        </button>
        <ng-template #mymodall let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              New Team
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <ngb-alert *ngIf="error" type="danger" [dismissible]="false">{{
              error
            }}</ngb-alert>
            <form (ngSubmit)="submitted(form)" #form="ngForm">
              <div class="mb-2">
                <label for="exampleInputEmail1" class="form-label"
                  >Departements:</label
                >
                <ng-multiselect-dropdown
                name="deptname"
                [settings]="dropdownSettingsDD"
                [data]="departments"
                [(ngModel)]="selectedDepartment"
                (onSelect)="onItemSelect($event)"
                [disabled]="disabled"
                ></ng-multiselect-dropdown>
              </div>

              <div class="mb-2">
                <label for="name" class="form-label">Team Name :</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  [(ngModel)]="teamName"
                  required
                  placeholder="Input the Team Name"
                />
              </div>
              <div class="mb-2">
                <label for="depdescription" class="form-label"
                  >Team Description:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  name="teamdesc"
                  placeholder="Input the Team Description"
                  [(ngModel)]="teamDesc"
                  required
                />
              </div>
              <div class="form-check form-check-inline" *ngIf="isupdate">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  [value]="true"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Active</label
                >
              </div>
              <div class="form-check form-check-inline" *ngIf="isupdate">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  [value]="false"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio2"
                  >InActive</label
                >
              </div>
              <div class="mt-2">
                <button
                  class="btn"
                  (click)="modal.close('Save click')"
                  [disabled]="!form.valid"
                >
                  {{ isupdate ? "update" : "Submit" }}
                </button>
              </div>
            </form>
          </div>
        </ng-template>
        <ng-template #mymodal let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              {{ isupdate ? "Update" : "New" }} Team
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <form (ngSubmit)="submitted(form)" #form="ngForm">
              <div class="mb-2">
                <label for="exampleInputEmail1" class="form-label"
                  >Team Leads:</label
                >
                <ng-multiselect-dropdown
                name="manager"
                [settings]="dropdownSettings"
                [data]="teams"
                [(ngModel)]="selectedItem"
                (onSelect)="onItemSelect($event)"
                [disabled]="disabled"
                ></ng-multiselect-dropdown>
              </div>
              <div class="mb-2">
                <label for="exampleInputEmail1" class="form-label"
                  >Departements:</label
                >
                <ng-multiselect-dropdown
                name="deptname"
                [settings]="dropdownSettingsD"
                [data]="departments"
                [(ngModel)]="selectedDepartment"
                (onSelect)="onItemSelect($event)"
                [disabled]="disabled"
                ></ng-multiselect-dropdown>
              </div>

              <div class="mb-2">
                <label for="name" class="form-label">Team Name :</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  [(ngModel)]="teamName"
                  required
                  placeholder="Input the Team Name"
                />
              </div>
              <div class="mb-2">
                <label for="depdescription" class="form-label"
                  >Team Description:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  name="teamdesc"
                  placeholder="Input the Team Description"
                  [(ngModel)]="teamDesc"
                  required
                />
              </div>
              <div class="form-check form-check-inline" *ngIf="isupdate">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  [value]="true"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Active</label
                >
              </div>
              <div class="form-check form-check-inline" *ngIf="isupdate">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  [value]="false"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio2"
                  >InActive</label
                >
              </div>
              <div class="mt-2">
                <button
                  class="btn"
                  (click)="modal.close('Save click')"
                  [disabled]="!form.valid"
                >
                  {{ isupdate ? "update" : "Submit" }}
                </button>
              </div>
            </form>
          </div>
        </ng-template>

        <!-- <app-teamspagedropdown></app-teamspagedropdown> -->
      </div>
      <div class="col-4"></div>
      <div class="col-md-5 col-sm-12 padserach">
        <div class="form-group has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="search"
          />
        </div>
      </div>
    </div>
    <div class="row" style="margin: 0" >
      <div class="departmentauto" >
        <table
          id="customers"
          class="styled-table"
          cellspacing="1"
          cellpadding="1"
        >
          <thead>
            <tr style="font-size: 14px">
              <th>Team Name</th>
              <th>Department</th>
              <th>{{this.loginData.role==='mgr'?"Team Lead":"Manager"}}</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let team of teamsList | filter: search">
              <td *ngIf="this.loginData.role === 'team'"
                class="px-5 text-capitalize"
              >
                {{ team["TEAM_NAME"] }}</td>
                <td *ngIf="this.loginData.role != 'team'"
                class="px-5 text-primary text-capitalize"
                style="cursor: pointer"
                (click)="populateValues(mymodal, team)"
              >
                {{ team["TEAM_NAME"] }} </td>
                <td class="text-capitalize">{{ team["DEPT_ID"].dept_name }}</td>
              <td *ngIf="this.loginData.role === 'admin'" class="text-capitalize">
                <span *ngFor="let item of team['MGR_ID'] let last=last">
                  {{item["emp_name"]}} {{last? '':','}}</span></td>
                  <td *ngIf="this.loginData.role === 'mgr'" class="text-capitalize">
                    <span *ngFor="let item of team['TEAM_LEAD_ID'] let last=last">
                      {{item["emp_name"]}} {{last? '':','}}</span></td>
                      <td *ngIf="this.loginData.role === 'team'" class="text-capitalize">
                        <span *ngFor="let item of team['TEAM_LEAD_ID'] let last=last">
                          {{item["emp_name"]}} {{last? '':','}}</span></td>
              <td class="text-capitalize">{{ team["TEAM_DESC"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav aria-label="Page navigation example" class="mt-2">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" (click)="pagination.onFirst()">First</a>
          </li>
          <li class="page-item">
            <a class="page-link" (click)="pagination.onPrevious()">Previous</a>
          </li>
          <input
            #pagination="pagination"
            appPagination
            type="number"
            min="1"
            max=this.totalPages
            disabled
            [totalPages]=this.totalPages
            value="1"
            (onChangeEventEmitter)="onPageChange($event)"
          />
          <li class="page-item">
            <a class="page-link" (click)="pagination.onNext()">Next</a>
          </li>
          <li class="page-item">
            <a class="page-link" (click)="pagination.onLast()">Last</a>
          </li>
        </ul>
      </nav>
    </div>
    
  </div>
  
</div>
