// import { SocketOne } from './../socket.service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { employeemodel } from 'src/app/models/employee.model';
import { appservice } from '../app.service';
import { ManagerService } from './../services/manager.service';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'app-employeelist',
  templateUrl: './employeelist.component.html',
  styleUrls: ['./employeelist.component.css'],
  // providers: [SocketOne]
})
export class EmployeelistComponent implements OnInit {
  baseUrl = environment.baseUrl;
  [x: string]: any;
  employeedata: employeemodel[] = []
  loading: boolean = true;
  search: string = '';
  totalPages!: number;
  constructor(private http: HttpClient, private service: appservice, private managerService: ManagerService) { }
  ngOnInit(): void {
    console.log("ngOnInit");
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    this.OrgTotalLicenses();
    this.fetchEmployees(1);
    this.service.submitted.subscribe(val => {
      this.fetchEmployees(1);
    })
  }

  OrgTotalLicenses(){
    this.http.get(`${this.baseUrl}availabilityoranizationlicenses?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe((data: any) => {
      console.log("OrgTotalLicenses");
      console.log(data);
      if (data['status']) {
        this.OrgTotalLicense = data.count;
        //this.totallicenses = data['total_licenses'];
      }
    })
  }


  fetchEmployees(pageno: number) {
    this.http.get(`${this.baseUrl}workers?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&page_no=${pageno}&clientId=${this.loginData.clientId}`).pipe(map((res: any) => {
    let emp2: employeemodel[] = [];
      let getResp = res.listOfEmployess;
      this.totalPages = res['total_pages'];
      for (let employee of getResp) {
        let _id = employee['_id']
        let emp_name = employee['emp_name']
        let emp_email = employee['emp_email']
        let emp_last_login = employee['emp_last_login']
        let emp_departement = employee['dept_id'][0].dept_name
        let emp_lic_assigned_from = employee['emp_lic_assigned_from']
        let emp_lic_assigned_till = employee['emp_lic_assigned_till']
        let emp_lic_id = employee['emp_lic_id']
        let emp_login_status = employee['emp_login_status']
        let emp = new employeemodel(_id, emp_name, emp_email, emp_last_login, emp_departement, emp_lic_assigned_from, emp_lic_assigned_till, emp_lic_id, emp_login_status)
        emp2.push(emp)
        
      }
      //emp2.push(tot)
      return emp2
    })).subscribe((response) => {
      let offline = response.filter(val => val['emp_login_status']===false);
      let online = response.filter(val => val['emp_login_status'] === true);
      this.employeedata = [...online, ...offline];
      //console.log(this.employeedata)
      this.loading = false
    })

  }
  onPageChange(pageno: number) {
    this.fetchEmployees(pageno);
  }
}
