<app-topnav></app-topnav>
<app-sidenavbar></app-sidenavbar>
<div class="main-container">
  <div class="col-12 title-section">
    <h3>Employee List (Organization Available Total Licenses : {{ this.OrgTotalLicense ? this.OrgTotalLicense : "?" }})</h3>
    
  </div>
  <div class="container-fluid">
    <div class="row">
      
        <div class="col-md-4 col-sm-12 add-btn-par">
          <app-empbackdrop></app-empbackdrop>
          <!-- <app-violationbackdrop></app-violationbackdrop> -->
        </div>
        <div class="col-md-8 col-sm-12" style="margin-bottom: 20px;">
          <div class="row">
          <div class="col-md-6 col-sm-12"></div>
          <div class="col-md-6 col-sm-12 emp-pr">
            <span>Search:</span>
            <input type="serach" class="form-control search-box" [(ngModel)]="search" />
          </div>
        </div>
      </div>
     </div>
      <div class="row m-2">
        <div class="col-12" style="overflow-x: auto;white-space:auto;">
          <table id="customers">
            <thead style="font-size: 14px">
              <tr class="table100-head">
                <th>License Key</th>
                <th>Employee</th>
                <th>Email</th>
                <th>Departement</th>
                <th>Last login time</th>
                <th>On board Date</th>
                <th>Monitored</th>
                <th>Status</th>
              </tr>
            </thead>
            <div *ngIf="loading">
              <app-loadingspinner></app-loadingspinner>
            </div>

            <tbody
              *ngFor="let item of employeedata | filter: search; let i = index"
            >
              <tr>
                <td class="column1">{{ item["emp_lic_id"] }}</td>
                <td class="column2">{{ item["emp_name"] }}</td>
                <td class="column3">
                  {{ item["emp_email"] !== undefined ? item["emp_email"] : "N/A" }}
                </td>
                <td class="column4">{{item["emp_departement"]}}</td>
                <td class="column5">
                  {{
                    item["emp_last_login"] !== null
                      ? (item["emp_last_login"] | date: "short")
                      : "N/A"
                  }}
                </td>
                <td class="column6">
                  {{
                    item["emp_lic_assigned_till"] !== null
                      ? (item["emp_lic_assigned_till"] | date: "short")
                      : "N/A"
                  }}
                </td>
                <td class="column7">Yes</td>
                <td
                  class="column8"
                  [ngClass]="{
                    'text-danger': item['emp_login_status'] === false,
                    'text-success': item['emp_login_status'] === true
                  }"
                >
                  {{ item["emp_login_status"] }}
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example" class="mt-2">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" (click)="pagination.onFirst()">First</a>
              </li>
              <li class="page-item">
                <a class="page-link" (click)="pagination.onPrevious()">Previous</a>
              </li>
              <input
                #pagination="pagination"
                appPagination
                type="number"
                min="1"
                max=this.totalPages
                disabled
                [totalPages]=this.totalPages
                value="1"
                (onChangeEventEmitter)="onPageChange($event)"
              />
              <li class="page-item">
                <a class="page-link" (click)="pagination.onNext()">Next</a>
              </li>
              <li class="page-item">
                <a class="page-link" (click)="pagination.onLast()">Last</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

