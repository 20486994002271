import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
// import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { appservice } from '../../app.service';
import { ManagerService } from '../../services/manager.service';
import { SharedService } from '../../services/shared.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from '../../../../environments/environment';
// import { from } from 'rxjs';
@Component({
  selector: 'app-empbackdrop',
  templateUrl: './empbackdrop.component.html',
  styleUrls: ['./empbackdrop.component.css']
})
export class EmpbackdropComponent implements OnInit {
  baseUrl = environment.baseUrl;
  [x: string]: any;
  @ViewChild('form') form: NgForm | undefined
  dropdownSettingsDD!: IDropdownSettings;
  closeDropdownSelection = false;
  closeResult: string | undefined;
  totallicenses: any[] = [];
  deptTeams: any[] = [];
  userData: any;
  clientId: any;
  departments: any = [];
  constructor(private modalService: NgbModal, private shared_Service: SharedService, private http: HttpClient, private service: appservice, private managerService: ManagerService) {
    this.clientId = this.shared_Service.clientId;
    this.userData = this.shared_Service.userData;
   }

  open(content: any) {
    this.getlicenses();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.totallicenses = [];
      this.getlicenses();
      return `with: ${reason}`;
    }
  }
  ngOnInit() {
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    this.getlicenses();
    this.http.get(`${this.baseUrl}departementnames?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe((depart_data: any) => {
      this.departments = depart_data['departments'];
      this.listOfTeams = depart_data['listOfTeams'];
      console.log("departments");
      console.log(depart_data);
      console.log("Teams");
      console.log(this.listOfTeams);
    })
    this.dropdownSettingsDD = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "dept_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
  }
  
  getlicenses() {
    this.managerService.getLicenses().subscribe((data: any) => {
      if (data['status']) {
        this.totallicenses = data['total_licenses'];
      }
    })
  }
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    let depts = this.listOfTeams;
    let teams:any = [];
    console.log(this.listOfTeams);
    console.log("filter");
    depts.forEach((v:any) => {
      // let d_id = ;
      // let t_id = v.dept_id
      // console.log(v)
      if(v.dept_id.toString() === item._id.toString())
      {
        teams.push(v)
      }
      
    });
    this.deptTeams = teams
    console.log(this.deptTeams);
    // this.listOfTeams = this.listOfTeams.filter((val:any) => depts.find((v:any) => v._id.includes(val.dept_id)));
    //     console.log("listOfTeams");
    //     console.log(this.violationapps);
    //this.listOfTeams = this.listOfTeams()
  }
  toggleCloseDropdownSelection(){
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettingsDD = Object.assign({}, this.dropdownSettingsDD, { closeDropDownOnSelection: this.closeDropdownSelection });
  }
  submitted(value: any) {
    console.log("addEmployee");
    console.log(value);
    // const body = {
    //   name: value.empname,
    //   licenseKey: value.emplicense._id,
    //   license_start:value.emplicense.license_start,
    //   license_end:value.emplicense.license_end,
    //   mgr_id:this.userData._id,
    //   org_id:this.userData.org_id,
    //   emp_token:this.userData.token,
    //   clientId:this.clientId,
    //   email: value.email,
    // }
    
    // console.log(body)
    let mgr_id = value.dept_team.mgr_id;
    let team_lead_id = value.dept_team.team_lead_id
    let d_mgr_id;
    let t_lead_id;
    if(mgr_id.length === 0) {
      d_mgr_id = '';
    } else {
      d_mgr_id = mgr_id[0];
    }
    if(team_lead_id.length === 0) {
      t_lead_id = '';
    } else {
      t_lead_id = team_lead_id[0];
    }
    if (value.emplicense === 'No licences found') return
    if (value.empname === '') return
    if (value.email === '') return
    
    this.addEmployee(value.empname, value.emplicense._id, value.deptname[0]._id, value.dept_team._id,value.emplicense.license_start, value.emplicense.license_end, value.email, d_mgr_id, t_lead_id, this.loginData.primary_mgr, this.loginData._id, this.loginData.org_id, this.loginData.token, this.loginData.clientId);
    
    // this.http.post(`https://api.pulseye.co.uk/createworker`, body).subscribe((res: any) => {
    //   this.service.submitted.next('submitted');
    // })
    // this.getlicenses();
  }
  addEmployee (name: string, licenseKey: string, dept_id: string, team_id: string, license_start: any, license_end: any, email: string, d_mgr_id: string, t_lead_id: string, primary_mgr_id:string, mgr_id: string, org_id: string,  emp_token: string, clientId: string ) {
    let body = {
      name:name,
      licenseKey:licenseKey,
      dept_id:dept_id,
      team_id:team_id,
      license_start:license_start,
      license_end:license_end,
      email:email,
      d_mgr_id:d_mgr_id,
      t_lead_id:t_lead_id,
      primary_mgr_id:primary_mgr_id,
      mgr_id:mgr_id,
      org_id:org_id,
      emp_token:emp_token,
      clientId:clientId
    }; 
    console.log("addEmployee");
    console.log(body);
    this.http.post(`${this.baseUrl}createworker`, body).subscribe((data: any) => {
      console.log(data);
      this.getlicenses();
      this.service.submitted.next('submitted');
    })
  }

}
