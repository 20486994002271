import { ManagerService } from './../services/manager.service';
import { Departement } from './../../models/departement.model';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ContentChild, ElementRef, TemplateRef, ViewChild, ViewContainerRef, ViewChildren, AfterViewInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { appservice } from '../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from './../../../environments/environment';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Component({
  selector: 'app-departementpage',
  templateUrl: './departementpage.component.html',
  styleUrls: ['./departementpage.component.css']
})
export class DepartementpageComponent implements OnInit {
  baseUrl = environment.baseUrl;
  [x: string]: any;
  @ViewChild('modalContentBody', { read: ViewContainerRef }) formc!: ElementRef;
  closeResult: string | undefined;
  managers: any[] = [];
  selectedItem: any = [];
  dropdownSettings!: IDropdownSettings;
  closeDropdownSelection = false;
  disabled = false;
  update = false;
  deptname: string = '';
  deptdesc: string = '';
  departmentsList: Departement[] = [];
  active!: Boolean;
  search: string = '';
  id: any;
  page = 1;
  pageSize = 8;
  totalPages!: number;
  collectionSize!: number;
  paginatedata: Departement[] = [];
  error: string = '';
  // countries: Country[];

  constructor(private modalService: NgbModal, private service: appservice, private http: HttpClient, private managerService: ManagerService) {
    // this.refreshCountries();

  }
  refreshCountries() {
    this.paginatedata = this.departmentsList.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    console.log(this.departmentsList);
  }


  ngOnInit(): void {
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    let url = this.service.url;
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    //this.dataRole = JSON.parse(`${localStorage.getItem('userData')}`);
    //console.log("departments")
    //console.log(this.dataRole.role)
    //this.role = this.dataRole.role;
    this.fetchDepartements(1);
    
    this.dropdownSettings = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "emp_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
    
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }
  submitted(form: NgForm) {
    console.log(form)
    let item
    let l = this.selectedItem;
    console.log(this.selectedItem);
    if(l.length !=0)
    {
      item = this.selectedItem.map((data: any) => data['_id']);
    } else {
      item = '';
    }
     // body['clientId'] = this.clientId; JSON.parse(`${localStorage.getItem('userData')}`)
    // body['org_id'] = this.userData.org_id;
    // body['emp_token'] = this.userData.token;
    // body['mgr_id'] = this.userData._id;
    // body['primary_mgr_id'] = this.userData.primary_mgr; token role _id primary_mgr org_id
    console.log(this.deptname);
    console.log(this.deptdesc);
    console.log(item);
    if (this.update) { 
      //console.log(this.id,this.deptname,this.deptdesc,item,this.active);
     this.updateDepartments(this.id, this.deptname, this.deptdesc, item, this.active)
      return
    }
    this.createDepartment(this.deptname, this.deptdesc, item, this.loginData.primary_mgr, this.loginData._id, this.loginData.org_id, this.loginData.token, this.loginData.clientId);
  }

  open(content: TemplateRef<ElementRef>) {
    // console.log(content);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.update = false;
    });
  }

  private getDismissReason(reason: any): string {
    this.update = false;
    this.selectedItem = [];
    this.deptname = '';
    this.deptdesc = '';
    this.active = true;

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
  populatedata(mymodal: any, departement: Departement) {
    this.team_id='';
    this.http.get<any>(`${this.baseUrl}managernames?dept_id=${departement['_id']}&team_id=${this.team_id}&primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe(data => {
      
      console.log(`${this.baseUrl}managernames?dept_id=${departement['_id']}&team_id=''&primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`);
      console.log(this.loginData.primary_mgr);
      console.log(this.loginData._id);
      console.log("Department_managers");
      console.log(data);
      this.managers = data['listOfEmployess'];
    })
    this.update = true;
    this.open(mymodal);
    this.selectedItem = departement['managers'];
    this.deptname = departement['dept_name'];
    console.log(this.deptname);
    this.deptdesc = departement['dept_desc'];
    this.active = departement['is_dept_active'];
    this.id = departement['_id'];
    console.log(this.id);

  }
  
  fetchDepartements(pageno: number) {
    this.http.get(`${this.baseUrl}departments?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&page_no=${pageno}&clientId=${this.loginData.clientId}`).subscribe((res: any) => {
      this.totalPages = res['total_pages'];
      this.departmentsList = res['listOfDepartments'].map((data: any) => new Departement(data['dept_name'], data['dept_desc'], data['mgr_id'], data['_id'], data['is_dept_active']))
      this.collectionSize = this.departmentsList.length;
      this.paginatedata = this.departmentsList;
      this.refreshCountries();
      console.log(this.collectionSize);
    })

  }
  updateDepartments(id: any, name: string, deptdesc: string, managers: Array<string>, active: Boolean) {
    console.log({ id: id, name: name, deptdesc: deptdesc, managers: managers, active: active });
    let body = {
      id:id,
      name: name, 
      deptdesc: deptdesc,
      managers: managers,
      active: active,
      primary_mgr_id:this.loginData.primary_mgr,
      mgr_id:this.loginData._id,
      org_id:this.loginData.org_id,
      emp_token:this.loginData.token,
      clientId:this.loginData.clientId
    }
    console.log(body);
    this.http.put(`${this.baseUrl}updatedepartement`,body).subscribe((data: any) => {
      console.log(data);
      if (data['status']) {
        this.modalService.dismissAll();
        this.fetchDepartements(1)
      } else {
        this.error = data['message'];
        setTimeout(() => {
          this.error = ''
        }, 1000)
      }
    })
  }
  createDepartment = (name: string, deptdesc: string, managers: Array<string>, primary_mgr_id:string, mgr_id: string, org_id: string,  emp_token: string, clientId: string) => {
   let body = { name, deptdesc, managers, primary_mgr_id, mgr_id, org_id,  emp_token, clientId};
   this.http.post(`${this.baseUrl}createdepartement`, body).subscribe((data: any) => {
      if (data['status']) {
        this.modalService.dismissAll();
        this.fetchDepartements(1)
      } else {
        this.error = data['message'];
        setTimeout(() => {
          this.error = ''
        }, 1000)
      }
    })
  }
  onPageChange(pageno: number) {
    this.fetchDepartements(pageno);
  }
}
