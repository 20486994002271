import { DesktopApp } from './models/desktop.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { employeemodel } from 'src/app/models/employee.model';
import { typeofpackages } from 'src/app/models/typeofpackages.model'
import { appservice } from '../app.service';
import { Website } from './models/website.model';
import { ManagerService } from './../services/manager.service';

@Component({
  selector: 'app-addviolation',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  [x: string]: any;

  empnames: Array<string> = [];
  selectedemp: any;
  dropdownSettings: any;
  selectedWebsite: any;
  selectedApp: any;
  Webname: any[] = [];
  webNames: any[] = [];
  appNames: any[] = [];
  appName: any[] = [];
  violationsites: Website[] = [];
  violationapps: DesktopApp[] = [];
  message: string = '';
  isalert: boolean = false;
  alerttype: string = '';
  error: string = '';
  constructor(private router: Router,private http: HttpClient, private service: appservice, private managerService: ManagerService, private modalService: NgbModal, ) { }

  ngOnInit(): void {
    this.actualpric;
    this.disscountprice;
    this.priceStatus = false;
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    /* Srini */
    /* Desktop Apps & Desktop Sites Fetching */
    this.dropdownSettings = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "emp_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
      this.managerService.getTypOfPackages().pipe(map((res: any) => {
        let emp2: typeofpackages[] = []
      let getResp = res['typeOfPackges'];
      for (let types of getResp) {
        let _id = types['_id'];
        let package_type = types['package_type'];
        let price = types['price'];
        let currency_symboll = types['currency_symboll'];
        let disscount = types['disscount'];
        let duration = types['duration'];
        let emp = new typeofpackages(_id, package_type, price, currency_symboll, disscount,duration)
        emp2.push(emp)
        
      }
      return emp2
      })).subscribe((res: any) => {
         console.log("managerService res");
         console.log(res);
        this.empnames = res.map((emp:any) => {
          return {
            _id:emp['_id'],
            emp_name:emp['package_type'],
            price:emp['price'],
            currency_symboll:emp['currency_symboll'],
            disscount:emp['disscount'],
            duration:emp['duration']
            
          }
        })
      })
    /* Srini */
  }

  submitted(value: any) {
    console.log("submitted");
    console.log(value);
    let package_type = value.empnames[0]._id;
    let package_details = value.package_details;
    let price = value.price;
    let duration = value.duration;
    let live_discount = value.disscountprice;
    let license_count = value.license_count;
    let org_name = value.orgname;
    let admin_name = value.empname;
    let admin_password = value.password;
    let admin_email = value.email;
    let shift_hours = value.shifthours;
    let clientId = "e688f3eb8c985643081df0c74e45c3dc1";
    let body = {
      package_type:package_type,
      package_details:package_details,
      price:price,
      duration:duration,
      live_discount:live_discount,
      license_count:license_count,
      org_name:org_name,
      admin_name:admin_name,
      admin_password:admin_password,
      admin_email:admin_email,
      shift_hours:shift_hours,
      clientId:clientId
    }
    
    this.managerService.createOrganizationLicense(body).subscribe((data: any) => {
      console.log(data);
      this.router.navigate(['/']);
    })

  }

  
  onBlurEvent(event: any){
    console.log("onBlurEvent");
    console.log(event.target.value);
    this.actualpric = parseInt(this.price,10)*parseInt(event.target.value,10);
    this.disscountprice = parseInt(this.actualpric,10)-parseInt(this.disscount,10)*parseInt(event.target.value,10);
   console.log(parseInt(this.actualpric,10));
   console.log(parseInt(this.disscountprice,10));
   this.priceStatus = true;
  }
  /* Srini */
   /* Srini */
   onItemSelect(name: any) {
    console.log("onItemSelect");
    //console.log(this.empnames)
    // console.log(name);
     this.data = this.empnames.filter((val:any) => val['package_type']===name.package_type);
     console.log(this.data);
    this.price = this.data[0].price;
    this.disscount = this.data[0].disscount;
    this.currencySyambol = this.data[0].currency_symboll;
    this.duration = this.data[0].duration
    console.log(this.price);
    console.log(this.disscount);
    console.log(this.currencySyambol);
  }


}
