<app-card>
    <table>
        <thead>
            <tr>
                <th>Employee name</th>
                <th>Computer</th>
                <th>status</th>
                <th>current Activity</th>
                <th>TimeWorked</th>
                <th>Ram-Consumption</th>
                <th>Live</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{employeeonline}}</td>
                <td>{{loading}}</td>
            </tr>
        </tbody>
    </table>
</app-card>
