
<app-card>
    <div *ngIf="loading">
        <app-loadingspinner2></app-loadingspinner2>    
    </div>
    <h1 class="h5 dashheadding">Productivity</h1>
    <div class="graph">
        <canvas id="myChart"></canvas>
    </div>
</app-card>


