export class employeemodel {
    constructor(
        private _id: string,
        private emp_name: string,
        private emp_email: string,
        private emp_last_login: string,
        private emp_departement: string,
        private emp_lic_assigned_from: string,
        private emp_lic_assigned_till: string,
        private emp_lic_id: string,
        private emp_login_status: Boolean
    ) { }
}
