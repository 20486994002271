import { environment } from './../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface Login {
  email: string,
  password: string,
  fcmToken: string,
  device_id: string,
  clientId: string
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  login(body: Login) {
    return this.http.post(`${this.baseUrl}login`, body);
  }


}
