export class Productivity {
        constructor(
                private _id:string,
                private activitydate:string,
                private empname:string,
                private system_name:string,
                private totalDuration:string,
                private productivityPercentage:string){}


}