import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productivity',
  templateUrl: './productivity.component.html',
  styleUrls: ['./productivity.component.css']
})
export class ProductivityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
