import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';

interface addEmployee {
  clientId?: string,
  email: string,
  dept_id: string,
  team_id: string,
  emp_token?: string,
  licenseKey: string,
  license_start: any,
  license_end: any,
  mgr_id?: string,
  name: string,
  org_id?: string,
  primary_mgr_id?: string,
  d_mgr_id: string,
  t_lead_id: string
}

interface createDepartement {
  name: string,
  deptdesc: string,
  managers: Array<string>,
  mgr_id?: string,
  org_id?: string,
  emp_token?: string,
  clientId?: string,
  primary_mgr_id?: string
}

interface UpdateDepartement {
  active: Boolean,
  clientId?: string,
  deptdesc: string,
  emp_token?: string,
  managers: Array<string>,
  id: string,
  name: string,
  org_id?: string,
  mgr_id?: string,
  primary_mgr_id?: string
}
interface UpdateTeam{
  id: string,
  clientId?: string,
  emp_token?: string,
  org_id?: string,
  mgr_id?: string
  name: string,
  teamdesc: string,
  manager: string,
  deptname: string,
  active: Boolean,
  primary_mgr_id?: string

}
interface CreateTeam {
  mgr_id?: string,
  org_id?: string,
  emp_token?: string,
  name: string,
  teamdesc: string,
  dept_id: string,
  manager_id: string,
  clientId?: string,
  primary_mgr_id?: string
}

interface AssignManagerfromAdmin {
  mgr_id?: string,
  org_id?: string,
  emp_token?: string,
  dept_id: string,
  clientId?: string,
  primary_mgr_id?: string,
  license_count: string,
  license_start: string,
  license_end: string,
  licenseKey: string,
  email: string,
  name: string,
  Is_emp_monitored: boolean
}

interface AssignTeamLead{
  mgr_id?: string,
  org_id?: string,
  emp_token?: string,
  dept_id: string,
  team_id: string,
  clientId?: string,
  primary_mgr_id?: string,
  license_count: string,
  license_start: string,
  license_end: string,
  licenseKey: string,
  email: string,
  name: string,
  Is_emp_monitored: boolean

}

interface CreateViolationSites {
  mgr_id?: String,
  org_id?: String,
  emp_token?: String,
  emp_id: any[],
  clientId?: String,
  violatiom_web_id: any[],
  violatiom_app_id: any[],
  primary_mgr_id?: string

}
interface addFnWeb {
  clientId?: string,
  emp_token?: string,
  violation_type: string,
  viloation_desc: string,
  mgr_id?: string,
  viloation_name: string,
  org_id?: string
}
interface addFnApp {
  clientId?: string,
  emp_token?: string,
  violation_type: string,
  viloation_desc: string,
  mgr_id?: string,
  viloation_name: string,
  org_id?: string
}
interface updateViolation{
  id: string,
  clientId?: string,
  emp_token?: string,
  org_id?: string,
  mgr_id?: string
  active: Boolean,

}
interface createOrganizationLicense {
  package_type:string,
      package_details:string,
      price:number
      duration:number,
      live_discount:number,
      license_count:number,
      org_name:string,
      admin_name:string,
      admin_password:string,
      admin_email:string,
      shift_hours:number,
      clientId:string
}


@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  baseUrl = environment.baseUrl;
  userData: any;
  clientId: any;

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.clientId = this.sharedService.clientId;
    this.userData = this.sharedService.userData;
  }
  fetchTeams(body:any){
    return this.http.get<any>(`${this.baseUrl}getteams?dept_id=${body.dept_id}&primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`);
  }
  getLicenses() {
    return this.http.get<any>(`${this.baseUrl}getlicenses?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`);
}
  getDepartements(){
    return this.http.get<any>(`${this.baseUrl}getdepartments?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`);
  }
  getEmployees(pageno: number) {
    return this.http.get(`${this.baseUrl}workers?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&page_no=${pageno}&clientId=${this.clientId}`);
  }
  addEmployee(body: addEmployee) {
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.post(`${this.baseUrl}createworker`, body);
  }
   
   addFnWeb(body: addFnWeb) {
    body['clientId'] = this.clientId;
    body['violation_type'] = 'web';
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    return this.http.post(`${this.baseUrl}createviolation`, body);
  }
  
  addFnApp(body: addFnApp) {
    body['clientId'] = this.clientId;
    body['violation_type'] = 'web';
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    return this.http.post(`${this.baseUrl}createviolation`, body);
  }

  getManagerNames() {
    return this.http.get<any>(`${this.baseUrl}managernames?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`)
  }

  createDepartement(body: createDepartement) {
    // body['clientId'] = this.clientId;
    // body['org_id'] = this.userData.org_id;
    // body['emp_token'] = this.userData.token;
    // body['mgr_id'] = this.userData._id;
    // body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.post(`${this.baseUrl}createdepartement`, body);
  }

  getAllDepartements(pageno: number) {
    return this.http.get(`${this.baseUrl}departments?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&page_no=${pageno}&clientId=${this.clientId}`)
  }
  // orgTotalLicenses(body:any){
  //   return this.http.get(`${this.baseUrl}availabilityoranizationlicenses?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`)
  // }

  departementNames() {
    return this.http.get(`${this.baseUrl}departementnames?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`)
  }
  

  updateDepartment(body: UpdateDepartement) {
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.put(`${this.baseUrl}updatedepartement`, body);
  }
  
  updateTeams(body: UpdateTeam) {
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.put(`${this.baseUrl}updateteam`, body);
  }

  createTeam(body: CreateTeam) {
    return this.http.post(`${this.baseUrl}createteam`, body);
  }
  assignManagerfromAdmin(body: AssignManagerfromAdmin){
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.post(`${this.baseUrl}assignedmanagerfromadmin`, body);
  }
  assignTeamLead(body: AssignTeamLead){
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.post(`${this.baseUrl}assignteamlead`, body);
  }
  getTeams(pageno: number) {
    return this.http.get(`${this.baseUrl}teams?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&page_no=${pageno}&clientId=${this.clientId}`)
  }
  
  createViolationSites(body: CreateViolationSites) {
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    body['primary_mgr_id'] = this.userData.primary_mgr;
    return this.http.post(`${this.baseUrl}createviolationsites`, body);
  }
  getViolationDesktopApp(name: any){
    return this.http.get(`${this.baseUrl}violationapps?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&name=${name}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`)

  }
  getViolationWebSites(name: any){
    return this.http.get(`${this.baseUrl}violationsites?primary_mgr_id=${this.userData.primary_mgr}&mgr_id=${this.userData._id}&name=${name}&org_id=${this.userData.org_id}&emp_token=${this.userData.token}&clientId=${this.clientId}`)

  }
  updateviolation(body: updateViolation) {
    body['clientId'] = this.clientId;
    body['org_id'] = this.userData.org_id;
    body['emp_token'] = this.userData.token;
    body['mgr_id'] = this.userData._id;
    return this.http.put(`${this.baseUrl}updateviolation`, body);
  }
  getTypOfPackages() {
    return this.http.get(`${this.baseUrl}typesofpackages
`);
  }
  createOrganizationLicense(body: createOrganizationLicense){
    return this.http.post(`${this.baseUrl}selectpackagewithcreateorganization`, body);
  }

}
