<app-card>
  <div *ngIf="loading">
    <app-loadingspinner2></app-loadingspinner2>
  </div>
  <div class="h5 dashheadding">App usage</div>
  <div *ngIf="!loading">
      <div *ngIf="appUsaesarr.length===0" class="mt-2 mess-show">No Data is available!</div>
    </div>
    <div class="app-graph-left">
    <canvas id="app-doughnut-chart" width="300" height="150"></canvas>
  </div>
  <div class="standard">
    <ul>
      <li *ngFor="let item of text">
        <div [ngStyle]="{'background-color': item['color']?item['color']:'white'}">
        </div>
        {{item['label']}} : {{item['data']}}%
      </li>
    </ul>
  </div>
</app-card>
