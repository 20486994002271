<div class="div">
  <div class="fixed-top top-nav">
    <div
      class="d-flex align-items-center"
    >
    <div class="mobile-menu">
      <span class="ham"><i class="fa fa-bars"></i></span>
      
    </div>
      <div class="logo">
        <img
          src="../../../assets/whiteeyelogo.svg"
          alt="logo"
          routerLink="/index"
        />
      </div>
     
    </div>

    <div
      class="d-flex align-items-center px-4"
    >
      
      <div class="profile">
        
        <div>Oraganization Registaration Fomrm</div>
      </div>
      <!-- <button class="btn btn-primary" (click)="logout()">
        Logout
      </button> -->
    </div>

    <!-- onclick dropdown -->

    <ul
      class="list-group dropnav-top"
     
      *ngIf="settings"
    >
      <a class="list-group-item">
        <i class="fa fa-user icon-section"></i>
        Profile
      </a>
      <!-- <div class="dropdown-divider"></div> -->
      <a
        class="list-group-item"
        (click)="open(mymodal)"
        ><i class="fa fa-cogs icon-section"></i>
        Settings
      </a>
      <a
        class="list-group-item"
        routerLink="/"
        (click)="logout()"
      >
        <i class="fa fa-sign-out icon-section"></i>
        Logout
      </a>
    </ul>
   
  </div>
</div>
<!-- settings modal -->
<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Settings</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4" style="height: 13rem">
        <div class="profilephoto">
          
          <div>
            <input
              type="file"
              name="file"
              id="file"
              #mydiv
              style="display: none"
              (change)="uploadfile($event)"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Username</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Username"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Desigation</label
          >
          <input
            class="form-control"
            type="text"
            id="exampleFormControlTextarea1"
            placeholder="Designation"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('Save click')"
    >
      CANCEL
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close('Save click')"
    >
      APPLY CHANGES
    </button>
  </div>
</ng-template>

<div class="main-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 title-section">
        <h3>Registaration Form</h3>
      </div>
    </div>
    <div class="row mx-5 my-2">
      <ngb-alert #selfClosingAlert [type]="alerttype" *ngIf="isalert">{{
        message
      }}</ngb-alert>
    </div>
    <div class="row m-5">
      <form (ngSubmit)="submitted(form.value)" #form="ngForm">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Organization Name :</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            name="orgname"
            ngModel
            required
          />
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Admin Name :</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            name="empname"
            ngModel
            required
          />
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label"
            >Admin Email :</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputPassword1"
            name="email"
            email
            ngModel
            required
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Admin Passord :</label
          >
          <input
            type="password"
            class="form-control"
            id="exampleInputEmail1"
            name="password"
            ngModel
            required
          />
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2 my-1">
          <label for="depdescription" class="form-label"
            >Organization Shift Hours:</label
          >
          <input
            type="number"
            class="form-control"
            id="exampleInputPassword2"
            name="shifthours"
            placeholder="Please Organization Shift Hours"
            ngModel
            required
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Select Package Type:</label
          >
          <ng-multiselect-dropdown
          name="empnames"
          [settings]="dropdownSettings"
          [(ngModel)]="selectedemp"
          [data]="empnames"
          (onSelect)="onItemSelect($event)"
            required
          ></ng-multiselect-dropdown>
        </div>
        <div class="mb-2 my-1">
          <label for="depdescription" class="form-label"
            >Package Description:</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            name="package_details"
            placeholder="Input the Package Description"
            ngModel
            required
          />
        </div>
        <div class="mb-2 my-1">
          <label for="depdescription" class="form-label"
            >Package Duration(Months):</label
          >
          <input
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            name="duration"
            value=this.duration
            placeholder="Package Duration"
            [(ngModel)]=this.duration
            required
          />
        </div>
        <div class="mb-2 my-1">
          <label for="depdescription" class="form-label"
            >Each License Price {{this.currencySyambol}}:</label
          >
          <input
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            name="price"
            value=this.price
            placeholder="Each License Price"
            [(ngModel)]=this.price
            
            required
          />
        </div>
        <div class="mb-2 my-1">
          <label for="depdescription" class="form-label"
            >Each License Disscount Price  {{this.currencySyambol}}:</label
          >
          <input
            type="number"
            class="form-control"
            id="exampleInputPassword2"
            name="disscountprice"
            value=this.disscount
            placeholder="Each License Disscount Pricen"
            [(ngModel)]=this.disscount
            required
          />
        </div>
        <div class="mb-2 my-1">
          <label for="depdescription" class="form-label"
            >Total Licenses:</label
          >
          <input
            type="number"
            class="form-control"
            id="exampleInputPassword2"
            name="license_count"
            placeholder="Please enter total licenses"
            (blur)="onBlurEvent($event)"
            ngModel
            required
          />
        </div>
        <div *ngIf="this.priceStatus===true" class="mb-2 my-1">
          <h4 class="modal-title" id="modal-basic-title">
            Total Licenses Without Disscount Price : {{this.actualpric}} {{this.currencySyambol}}
            <br/>
            Total Licenses With Disscount Price : {{ this.disscountprice}} {{this.currencySyambol}}
          </h4>
          
        </div>
        <button class="btn add-btn" [disabled]="!form.valid">Submit</button>
      </form>
    </div>
    <div class="row">
    </div>
  </div>
</div>
