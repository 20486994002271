<app-topnav></app-topnav>
<app-sidenavbar></app-sidenavbar>
<div class="main-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 title-section">
        <h3>Violation</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12 mob-txt-center">
        <div class="custom-datepicker">
          <div id="reportrange">
            <span></span>
            <app-drpicker></app-drpicker>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mb-2"
      style="
        margin: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 8px;
      "
    >
      <div class="col-12">
        <div id="productivitychartsection">
          <app-violationgraphpage></app-violationgraphpage>
        </div>
      </div>
    </div>
    <div class="row" style="margin: 0;">
      <div class="col-12 custom-scroll" style="padding:40px 0 0 0;">
        <table id="customers">
          <thead>
            <tr>
              <th>Latest Activity Date</th>
              <th>Employee name</th>
              <th>Computer</th>
              <th>Latest violation app</th>
              <th>violation Duration</th>
              <th>violation Percentage</th>
              <th>Download</th>
            </tr>
          </thead>
          <div *ngIf="loading">
            <app-loadingspinner></app-loadingspinner>
          </div>

          <tbody *ngFor="let item of paginatedata; let i = index">
            <tr>
              <td>{{ item["activitydate"] }}</td>
              <td>{{ item["empname"] }}</td>
              <td>{{ item["system_name"] }}</td>
              <td>{{ item["latestAppUsed"] }}</td>
              <td>{{ item["totalDuration"] }}</td>
              <td>
                {{
                  item["usesPercentage"] !== null
                    ? item["usesPercentage"] + "%"
                    : "0%"
                }}
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-info table-btn"
                  (click)="download(i)"
                >
                  <i class="fa fa-download" style="color: white"></i> Download
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-2"></div>
    <div class="m-auto d-flex justify-content-center" *ngIf="!loading">
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshCountries()"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
