<app-topnav></app-topnav>
<app-sidenavbar></app-sidenavbar>
<div class="main-container">
  <div class="container-fluid">
    <div class="row" style="margin: 0">
      <div class="col-12 title-section">
        <h3>Departments</h3>
      </div>
    </div>
    <div class="row department-btn">
      <div class="col-md-3 col-sm-12 ptdep">
        <!-- new departement pop up window -->
        <button *ngIf="this.loginData.role === 'admin'" 
          class="btn btn-outline-primary add-btn"
          (click)="open(mymodall)"
          #f1
        >
          <i *ngIf="this.loginData.role === 'admin'"
            class="fa fa-plus-circle"
            aria-hidden="true"
            style="font-size: 18px"
          ></i>
          ADD DEPARTMENT
        </button>
        <ng-template #mymodall let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              New Department
            </h4>
            <button
              type="button"   
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <ngb-alert *ngIf="error" type="danger" [dismissible]="false">{{
              error
            }}</ngb-alert>
            <form  (ngSubmit)="submitted(f)" #f="ngForm">
              <div class="mb-2">
                <label for="name" class="form-label">Departement Name :</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  [(ngModel)]="deptname"
                  required
                  placeholder="Input the Departement Name"
                />
              </div>
              <div class="mb-2 my-1">
                <label for="depdescription" class="form-label"
                  >Department Description:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  name="deptdesc"
                  placeholder="Input the Department Description"
                  [(ngModel)]="deptdesc"
                  required
                />
              </div>
              <div class="form-check form-check-inline" *ngIf="update">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  [value]="true"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Active</label
                >
              </div>
              <div class="form-check form-check-inline" *ngIf="update">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  [value]="false"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio2"
                  >InActive</label
                >
              </div>
              <div class="mt-3">
                <button
                  class="btn btn-primary"
                  [disabled]="!f.valid"
                  type="submit"
                >
                  {{ update ? "update" : "submit" }}
                </button>
              </div>
            </form>
          </div>
        </ng-template>
        <ng-template #mymodal let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              {{ update ? "Update" : "New" }} Department
            </h4>
            <button
              type="button"   
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <ngb-alert *ngIf="error" type="danger" [dismissible]="false">{{
              error
            }}</ngb-alert>
            <form  (ngSubmit)="submitted(f)" #f="ngForm">
              <div class="mb-1">
                <label for="exampleInputEmail1" class="form-label"
                  >Department Managers:</label
                >
                <ng-multiselect-dropdown
                  name="managers"
                  [settings]="dropdownSettings"
                  [data]="managers"
                  [(ngModel)]="selectedItem"
                  (onSelect)="onItemSelect($event)"
                  [disabled]="disabled"
                >
                </ng-multiselect-dropdown>
              </div>

              <div class="mb-2">
                <label for="name" class="form-label">Departement Name :</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  [(ngModel)]="deptname"
                  required
                  placeholder="Input the Departement Name"
                />
              </div>
              <div class="mb-2 my-1">
                <label for="depdescription" class="form-label"
                  >Department Description:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  name="deptdesc"
                  placeholder="Input the Department Description"
                  [(ngModel)]="deptdesc"
                  required
                />
              </div>
              <div class="form-check form-check-inline" *ngIf="update">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  [value]="true"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Active</label
                >
              </div>
              <div class="form-check form-check-inline" *ngIf="update">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  [value]="false"
                  [(ngModel)]="active"
                  required
                />
                <label class="form-check-label" for="inlineRadio2"
                  >InActive</label
                >
              </div>
              <div class="mt-3">
                <button
                  class="btn btn-primary"
                  [disabled]="!f.valid"
                  type="submit"
                >
                  {{ update ? "update" : "submit" }}
                </button>
              </div>
            </form>
          </div>
        </ng-template>
      </div>
      <div class="col-4"></div>
      <div class="col-md-5 col-sm-12 padserach">
        <div class="form-group has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="search"
          />
        </div>
      </div>
    </div>
    <!-- departements table -->
    <div class="row" style="margin: 0">
      <div class="departmentauto" style="padding-left: 0">
        <table id="customers" class="styled-table">
          <thead>
            <tr>
              <th>Department Name</th>
              <th>Manager</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let department of paginatedata | filter: search">
              <td *ngIf="this.loginData.role === 'admin'" 
                class="px-5 text-capitalize text-primary"
                style="cursor: pointer"
                (click)="populatedata(mymodal, department)"
              >
                {{ department["dept_name"] }}
              </td>
              <td *ngIf="this.loginData.role != 'admin'" 
                class="px-5 text-capitalize"
              >
                {{ department["dept_name"] }}
              </td>
              <td class="text-capitalize">
                <span *ngFor="let item of department['managers']; let last=last">
                  {{item["emp_name"]}} {{last? '':','}}</span>
              </td>
              <td class="text-capitalize">
                {{ department["dept_desc"] }}
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example" class="mt-2">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" (click)="pagination.onFirst()">First</a>
            </li>
            <li class="page-item">
              <a class="page-link" (click)="pagination.onPrevious()">Previous</a>
            </li>
            <input
              #pagination="pagination"
              appPagination
              type="number"
              min="1"
              max=this.totalPages
              disabled
              [totalPages]=this.totalPages
              value="1"
              (onChangeEventEmitter)="onPageChange($event)"
            />
            <li class="page-item">
              <a class="page-link" (click)="pagination.onNext()">Next</a>
            </li>
            <li class="page-item">
              <a class="page-link" (click)="pagination.onLast()">Last</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  
</div>
