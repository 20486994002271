export class typeofpackages {
    constructor(
        private _id: string,
        private package_type: string,
        private price: number,
        private currency_symboll: string,
        private disscount: string,
        private duration: number
    ) { }
}
