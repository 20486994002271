<div class="div">
  <div class="fixed-top top-nav">
    <div
      class="d-flex align-items-center"
    >
    <div class="mobile-menu">
      <span class="ham"><i class="fa fa-bars"></i></span>
      
    </div>
      <div class="logo">
        <img
          src="../../../assets/whiteeyelogo.svg"
          alt="logo"
          routerLink="/index"
        />
      </div>
     
    </div>

    <div
      class="d-flex align-items-center px-4"
    >
      <div>
        <i class="fa fa-phone" style="color: white; font-size: 1.7rem"></i>
      </div>
      <div class="profile" (click)="settingclicker()">
        <div class="admin"></div>
        <div>{{ this.userName }}<i style="padding-left: 5px;" class="fa fa-caret-down" aria-hidden="true"></i></div>
      </div>
      <!-- <button class="btn btn-primary" (click)="logout()">
        Logout
      </button> -->
    </div>

    <!-- onclick dropdown -->

    <ul
      class="list-group dropnav-top"
     
      *ngIf="settings"
    >
      <a class="list-group-item">
        <i class="fa fa-user icon-section"></i>
        Profile
      </a>
      <!-- <div class="dropdown-divider"></div> -->
      <a
        class="list-group-item"
        (click)="open(mymodal)"
        ><i class="fa fa-cogs icon-section"></i>
        Settings
      </a>
      <a
        class="list-group-item"
        routerLink="/"
        (click)="logout()"
      >
        <i class="fa fa-sign-out icon-section"></i>
        Logout
      </a>
    </ul>
   
  </div>
</div>
<!-- settings modal -->
<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Settings</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4" style="height: 13rem">
        <div class="profilephoto">
          <div class="imagepreview">
            <img [src]="image" alt="user" (click)="upload(mydiv)" />
          </div>
          <div>
            <input
              type="file"
              name="file"
              id="file"
              #mydiv
              style="display: none"
              (change)="uploadfile($event)"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Username</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Username"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Desigation</label
          >
          <input
            class="form-control"
            type="text"
            id="exampleFormControlTextarea1"
            placeholder="Designation"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('Save click')"
    >
      CANCEL
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close('Save click')"
    >
      APPLY CHANGES
    </button>
  </div>
</ng-template>
