export class Appusage{
    constructor(
        private _id:string,
        private latestAppUsed:string,
        private empname:string,
        private system_name:string,
        private activitydate:string,
        private totalDuration:string,
        private usesPercentage:string
        ){

    }
}