import { NgForm } from '@angular/forms';
import { Team } from './../../models/team.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { appservice } from '../app.service';
import { ManagerService } from '../services/manager.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-teamspage',
  templateUrl: './teamspage.component.html',
  styleUrls: ['./teamspage.component.css']
})
export class TeamspageComponent implements OnInit {
  baseUrl = environment.baseUrl;
  [x: string]: any;
  
  closeResult: string | undefined;
  teams: any[] = [];
  departments: any = [];
  selectedTeam: any = [];
  selectedItem: any = [];
  selectedDepartment: any = [];
  dropdownSettings!: IDropdownSettings;
  dropdownSettingsD!: IDropdownSettings;
  dropdownSettingsDD!: IDropdownSettings;
  closeDropdownSelection = false;
  disabled = false;
  teamsList: Team[] = [];
  isupdate: boolean = false;
  teamName = '';
  teamLeadId: any = [];
  teamDesc = '';
  id = '';
  active!: boolean;
  deptid: string = '';
  search = '';
  error: string = '';
  totalPages!: number;

  constructor(
    private modalService: NgbModal,
    private service: appservice,
    private http: HttpClient,
    private managerService: ManagerService
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit");
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    console.log(this.loginData.role)
    this.role = this.loginData.role;
    this.managerService.departementNames().subscribe((depart_data: any) => {
      this.departments = depart_data['departments'];
      console.log("departments");
      console.log(depart_data);
    })
    
    
    
    /* Srini */
    this.dropdownSettings = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "emp_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
    this.dropdownSettingsD = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "dept_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
    this.dropdownSettingsDD = {
      singleSelection: true,
      defaultOpen: false,
      idField: "_id",
      textField: "dept_name",
      enableCheckAll: false,
      allowSearchFilter: true
    }
    
    /* Srini */
    this.fetchTeams(1);
  }
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }

  
  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
    this.dropdownSettingsD = Object.assign({}, this.dropdownSettingsD, { closeDropDownOnSelection: this.closeDropdownSelection });
    this.dropdownSettingsDD = Object.assign({}, this.dropdownSettingsDD, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  submitted(form: NgForm) {
    /*Srini*/
    let item
    let l = this.selectedItem;
    console.log(this.selectedItem);
    if(l.length !=0)
    {
      item = this.selectedItem.map((data: any) => data['_id']);
    } else {
      item = '';
    }
    
    let itemD = this.selectedDepartment.map((data: any) => data['_id']);
    /*Srini*/
    if (this.isupdate) {
      return this.updateTeams(this.id, this.teamName, this.teamDesc,item[0], itemD[0], this.active)
    }
    if(l.length !=0)
    {
    this.createTeam(form.value.name, form.value.teamdesc, form.value.manager[0], form.value.deptname[0], this.loginData.primary_mgr, this.loginData._id, this.loginData.org_id, this.loginData.token, this.loginData.clientId)
    } else {
      this.createTeam(form.value.name, form.value.teamdesc, item, form.value.deptname[0],  this.loginData.primary_mgr, this.loginData._id, this.loginData.org_id, this.loginData.token, this.loginData.clientId)

    }
  }


  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  populateValues(mymodal: any, team: Team) {
    this.isupdate = true;
    this.open(mymodal);
    if(team['MGR_ID'])
    {
      this.selectedItem = [team['MGR_ID']];
    } else {
      this.selectedItem = [];
    }
    this.selectedDepartment =[team['DEPT_ID']];
    console.log(this.selectedDepartment);
    this.teamName = team['TEAM_NAME'];
    this.teamLeadId = [team['TEAM_LEAD_ID']];
    this.teamDesc = team['TEAM_DESC'];
    this.active = team['IS_TEAM_ACTIVE'];
    this.id = team['_id'];
    console.log(this.id);
    console.log(`${this.baseUrl}managernames?dept_id=${this.selectedDepartment[0]._id}&team_id=${this.id}&primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`);
    this.http.get<any>(`${this.baseUrl}managernames?dept_id=${this.selectedDepartment[0]._id}&team_id=${this.id}&primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&clientId=${this.loginData.clientId}`).subscribe(data => {
     console.log(this.loginData.primary_mgr);
      console.log(this.loginData._id);
      console.log("Team_managers");
      console.log(data);
      this.teams = data['listOfEmployess'];
    })

  }

  private getDismissReason(reason: any): string {
    this.isupdate = false;
    this.teamName = '';
    this.teamLeadId = [];
    //this.teamDept
    this.teamDesc = '';
    this.id = '';
    this.selectedItem = [];
    this.selectedDepartment = [];
    this.active = true;
    this.deptid = '';
    // search = '';
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  fetchTeams(pageno: number) {
    this.http.get(`${this.baseUrl}teams?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&page_no=${pageno}&clientId=${this.loginData.clientId}`).subscribe((data: any) => {
      this.totalPages = data['total_pages'];
      if (data['status']) {
        console.log("teamsList");
        console.log(this.teamsList );
        this.teamsList = data['listOfTeams'].
          map(
            (data: any) =>
              new Team(data['team_name'], data['team_desc'], data['_id'], data['dept_id'], data['team_lead_id'], data['mgr_id'], data['is_team_active'])
          )
      }
    })
  }
  updateTeams(id: string, name: string, teamdesc: string, manager: string, deptname: string, active: Boolean) {
     console.log({ id: id, name: name, teamdesc: teamdesc, manager: manager,deptname:deptname, active: active });
    this.managerService.updateTeams({ id: id, name: name, teamdesc: teamdesc, manager: manager,deptname:deptname, active: active }).subscribe((data: any) => {
       
       if (data['status']) {
        this.modalService.dismissAll();
        this.fetchTeams(1)
      } else {
        this.error = data['message'];
        setTimeout(() => {
          this.error = ''
        }, 1000)
      }
    })
  }
  createTeam(name: string, teamdesc: string, manager: any, deptname: any, primary_mgr_id:string, mgr_id: string, org_id: string,  emp_token: string, clientId: string) {
    let manager_id;
    if(manager)
    {
      manager_id = manager._id;
    } else {
      manager_id = '';
    }
    let dept_id = deptname._id;
    let body = {
      manager_id:manager_id,
      dept_id:dept_id,
      teamdesc:teamdesc,
      name:name,
      primary_mgr_id:primary_mgr_id,
      mgr_id:mgr_id,
      org_id:org_id,
      emp_token:emp_token,
      clientId:clientId
    }
    console.log(body)
    this.managerService.createTeam(body).subscribe((data: any) => {
      console.log(data);
      //this.fetchTeams(1);
      if (data['status']) {
        this.modalService.dismissAll();
        this.fetchTeams(1)
      } else {
        this.error = data['message'];
        setTimeout(() => {
          this.error = ''
        }, 1000)
      }
    })
  }

  onPageChange(pageno: number) {
    this.fetchTeams(pageno);
  }


}
