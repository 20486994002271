<app-topnav></app-topnav>
<app-sidenavbar></app-sidenavbar>
<div class="main-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 title-section">
        <h3>Add Team Lead</h3>
      </div>
    </div>
    <div class="row mx-5 my-2">
      <ngb-alert #selfClosingAlert [type]="alerttype" *ngIf="isalert">{{
        message
      }}</ngb-alert>
    </div>
    <div class="row m-5">
      <form (ngSubmit)="submitted(form.value)" #form="ngForm">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Departements :</label
          >
          <select
          class="form-control form-control-sm"
          ngModel
          name="empDepartment"
          ngModel (ngModelChange)="onDepartmentChanged($event)"
          required
           >
          <option *ngIf="totalDepartments.length === 0">No departements found</option>
          <option *ngFor="let op of totalDepartments" [ngValue]="op">
            {{ op["dept_name"] }}
          </option>
        </select>
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div *ngIf="this.teaLic===true"class="row">
          <div class="col-12 title-section">
            <h3>Department Available Licenses : {{ this.deptLic }}</h3>
          </div>
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Team Lead License Key :</label
          >
          <select
          class="form-control form-control-sm"
          ngModel
          name="emplicense"
          required
        >
          <option *ngIf="totallicenses.length === 0">No licences found</option>
          <option *ngFor="let op of totallicenses" [ngValue]="op" >
            {{ op["_id"] }}
          </option>
        </select>
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
       
        <div class="mg-3">
          <label for="exampleInputEmail1" class="form-label"
            >Is Admin Monitored </label
          >
          <section>
            <h4>
               <input type="radio" name="Is_emp_monitored" id="Is_emp_monitored" ngModel="instructor.isActive" value=true required/> Yes
               <input type="radio" name="Is_emp_monitored" ngModel="instructor.isActive" value=false required>  No
           </h4>

       </section>
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Team Lead Name :</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            name="empname"
            ngModel
            required
          />
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label"
            >Team Lead Email :</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputPassword1"
            name="email"
            email
            ngModel
            required
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Add Licenses Count :</label
          >
          <input
            type="number"
            class="form-control"
            id="exampleInputLicenseCount"
            name="license_count"
            ngModel
            required
          />
          <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
          <label for="exampleInputEmail1" class="form-label"
            >Teams:</label
          >
          <ng-multiselect-dropdown
          name="teamname"
          [settings]="dropdownSettingsD"
          [data]="teams"
          [(ngModel)]="selectedDepartment"
          (onSelect)="onItemSelect($event)"
          [disabled]="disabled"
          ></ng-multiselect-dropdown>
        </div>
        <button class="btn add-btn" [disabled]="!form.valid">Submit</button>
      </form>
    </div>
  </div>
</div>
