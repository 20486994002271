import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements OnInit {
  [x: string]: any;


  constructor() {
    
   }
   userType?:string;
   data:any;
  ngOnInit(): void {
    
    this.udata = JSON.parse(`${localStorage.getItem('userData')}`);
    this.userType = this.udata.role;
    
  }
  isShown: boolean = true ;
  toggleShow() {

    this.isShown = ! this.isShown;
    
    }

  showDiv = {
    previous : false,
    
  }
}
