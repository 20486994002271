import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { employeemodel } from 'src/app/models/employee.model';
import { appservice } from '../../app.service';
import { environment } from 'src/environments/environment';
import { ManagerService } from '../../services/manager.service';
//import { ManagerService } from '../../services/manager.service';
//import { ManagerService } from './../services/manager.service';

@Component({
  selector: 'app-violationbackdrop',
  templateUrl: './violationbackdrop.component.html',
  styleUrls: ['./violationbackdrop.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class ViolationbackdropComponent implements OnInit {
  baseUrl = environment.baseUrl;

  closeResult: string | undefined;
  empnames: Array<string> = [];
  selectedemp: Array<string> = [];
  dropdownSettings: any;
  selectedWebsite: any;
  selectedApp: any;
  Webname: any[] = [];
  webNames: any[] = [];
  appNames: any[] = [];
  appName: any[] = [];
  totalPages!: number;
  loginData: any;
  constructor(private modalService: NgbModal, private http: HttpClient, private service: appservice,  private managerService: ManagerService) { }

  ngOnInit(): void {
    let token = localStorage.getItem('x-auth-token');
    this.loginData = JSON.parse(`${localStorage.getItem('userData')}`);
    this.fetchEmployees(1);
    this.service.submitted.subscribe(val => {
      this.fetchEmployees(1);
    })
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    this.http.get<any[]>(`${url}/api/violations/websites`, {
      headers: new HttpHeaders({
        'x-auth-token': t
      })
    }).subscribe((res) => {
      this.webNames = res;
      this.webNames.forEach((c, i) => {
        this.Webname.push({ id: i, name: c });
      });
    })

    this.http.get<any[]>(`${url}/api/violations/desktopapps`, {
      headers: new HttpHeaders({
        'x-auth-token': t
      })
    }).subscribe((res) => {
      this.appNames = res;
      this.appNames.forEach((c, i) => {
        this.appName.push({ id: i, name: c });
      });
    })


    this.dropdownSettings = {
      singleSelection: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    }
    
    

  }
  fetchEmployees(pageno: number) {

    this.http.get(`${this.baseUrl}workers?primary_mgr_id=${this.loginData.primary_mgr}&mgr_id=${this.loginData._id}&org_id=${this.loginData.org_id}&emp_token=${this.loginData.token}&page_no=${pageno}&clientId=${this.loginData.clientId}`).pipe(map((resp: any) => {
      let emp2: employeemodel[] = [];
      let getResp = resp.listOfEmployess;
      //localStorage.setItem('emp_total', res.total_pages)
      for (let employee of getResp) {
        let _id = employee['_id'];
        let emp_name = employee['emp_name'];
        let emp_email = employee['emp_email'];
        let emp_last_login = employee['emp_last_login'];
        let emp_departement = employee['emp_departement'];
        let emp_lic_assigned_from = employee['emp_lic_assigned_from'];
        let emp_lic_assigned_till = employee['emp_lic_assigned_till'];
        let emp_lic_id = employee['emp_lic_id'];
        let emp_login_status = employee['emp_login_status'];
        let emp = new employeemodel(_id, emp_name, emp_email, emp_last_login, emp_departement, emp_lic_assigned_from, emp_lic_assigned_till, emp_lic_id, emp_login_status)
        emp2.push(emp)
        
      }
      return emp2
    })).subscribe((res) => {
      this.empnames = res.map((emp) => {
        return emp['emp_name']
      })
    })
  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submitted(value: any) {
    let token = localStorage.getItem('x-auth-token')
    let t = JSON.parse(`${token}`)
    let url = this.service.url;
    console.log(value);
    this.http.post(`${url}/api/violations/createviolationweb`, {
      name: value.name[0],
      webname: value.webname
    }, {
      headers: new HttpHeaders({
        'x-auth-token': t
      })
    }).subscribe(res => console.log(res)
    )
    this.http.post(`${url}/api/violations/createviolationapp`, {
      name: value.name[0],
      appname: value.appname
    }, {
      headers: new HttpHeaders({
        'x-auth-token': t
      })
    }).subscribe(res => console.log(res)
    )
  }
  addTagFn(name: any) {
    return { name: name, tag: true };
  }
  onPageChange(pageno: number) {
    this.fetchEmployees(pageno);
  }
}

